import API_PATHS from '@constants/apiPaths';
import { centralHttp as http } from './index';
import { LanguageCode, LanguageMapping } from '@models/language';

let activeLanguageMappingCache: Partial<Record<LanguageCode, LanguageMapping>> =
  {};
let configuredLanguageMappingCache: Partial<
  Record<LanguageCode, LanguageMapping>
> = {};
let allLanguageMappingCache: Partial<Record<LanguageCode, LanguageMapping>> =
  {};

let lastConfiguredMappingCacheUpdateTime = 0;
let lastActiveMappingCacheUpdateTime = 0;
let lastAllLanguageMappingCacheUpdateTime = 0;

const configuredCacheRefreshInterval: number = 10 * 60 * 1000; // 10 minutes in milliseconds
const activeCacheRefreshInterval: number = 1 * 60 * 1000; // 1 minute in milliseconds
const allCacheRefreshInterval: number = 1 * 60 * 1000; // 1 minute in milliseconds

const languageApi = {
  getActiveLanguages: async (): Promise<any> => {
    if (
      Date.now() - lastActiveMappingCacheUpdateTime >=
      activeCacheRefreshInterval
    ) {
      const response = await http.get(API_PATHS.LANGUAGE, {
        params: {
          q: 'active',
        },
      });

      activeLanguageMappingCache = response.data.data.reduce(
        (
          cache: Partial<Record<LanguageCode, LanguageMapping>>,
          languageMapping,
        ) => {
          cache[languageMapping.languageCode] = languageMapping;
          return cache;
        },
        {},
      );
      lastActiveMappingCacheUpdateTime = Date.now();
    }

    return Object.values(activeLanguageMappingCache);
  },
  getConfiguredLanguages: async (): Promise<any> => {
    if (
      Date.now() - lastConfiguredMappingCacheUpdateTime >=
      configuredCacheRefreshInterval
    ) {
      const response = await http.get(API_PATHS.LANGUAGE, {
        params: {
          q: 'configured',
        },
      });

      configuredLanguageMappingCache = response.data.data.reduce(
        (
          cache: Partial<Record<LanguageCode, LanguageMapping>>,
          languageMapping,
        ) => {
          cache[languageMapping.languageCode] = languageMapping;
          return cache;
        },
        {},
      );
      lastConfiguredMappingCacheUpdateTime = Date.now();
    }

    return Object.values(configuredLanguageMappingCache);
  },
  getAllLanguages: async (): Promise<any> => {
    if (
      Date.now() - lastAllLanguageMappingCacheUpdateTime >=
      allCacheRefreshInterval
    ) {
      const response = await http.get(API_PATHS.LANGUAGE);

      allLanguageMappingCache = response.data.data.reduce(
        (
          cache: Partial<Record<LanguageCode, LanguageMapping>>,
          languageMapping,
        ) => {
          cache[languageMapping.languageCode] = languageMapping;
          return cache;
        },
        {},
      );
      lastAllLanguageMappingCacheUpdateTime = Date.now();
    }

    return Object.values(allLanguageMappingCache);
  },
  setLanguageAsConfigured: async (id: string): Promise<any> => {
    if (!id) return;

    const response = await http.put(
      API_PATHS.LANGUAGE_CONFIGURE.replace(':id', id),
    );

    return response.data.data;
  },

  unsetLanguageAsConfigured: async (id: string): Promise<any> => {
    if (!id) return;

    const response = await http.delete(
      API_PATHS.LANGUAGE_CONFIGURE.replace(':id', id),
    );

    return response.data.data;
  },

  activateLanguage: async (id: string): Promise<any> => {
    if (!id) return;

    await http.put(API_PATHS.LANGUAGE_ACTIVATE_DEACTIVATE.replace(':id', id));
  },

  deactivateLanguage: async (id: string): Promise<any> => {
    if (!id) return;

    await http.delete(
      API_PATHS.LANGUAGE_ACTIVATE_DEACTIVATE.replace(':id', id),
    );
  },

  updateLanguagePriorities: async (languageIds: string[]) => {
    if (!languageIds || !languageIds.length) return;

    await http.put(API_PATHS.LANGUAGE_UPDATE_PRIORITIES, { languageIds });
  },

  changeDefaultLanguage: async (id: string): Promise<any> => {
    if (!id) return;

    const response = await http.put(
      API_PATHS.LANGUAGE_CHANGE_DEFAULT.replace(':id', id),
    );

    return response.data.data;
  },
};

export default languageApi;

import WEB_PATHS from '@constants/webPaths';
import { LanguageDropdown } from '@ui-kit/v2/LanguageDropdown/LanguageDropdown';
import classNames from 'classnames';
import 'keen-slider/keen-slider.min.css';
import Image from 'next/image';
import Link from 'next/link';
import React, { ReactNode } from 'react';
import useTranslation from 'src/i18n/useTranslation';

interface LoginLayout {
  head?: React.ReactNode;
  children: ReactNode;
  showFooter?: boolean;
  contentScrollable?: boolean;
}

export default function LoginLayout({
  children,
  head,
  showFooter,
  contentScrollable,
}: LoginLayout) {
  const { t } = useTranslation();
  const contactEmail = t('signinPage.contactEmail').trim();
  return (
    <div
      className={classNames(
        `flex flex-col items-center justify-between px-6 py-8 lg:h-auto lg:px-20`,
        contentScrollable ? 'h-full lg:min-h-full ' : 'min-h-full',
      )}
    >
      {head}
      <div className="mb-4 self-end">
        <LanguageDropdown
          classNames={{
            container() {
              return 'lg:w-22 w-18';
            },
            valueContainer(props) {
              return 'lg:px-4 !pr-0 !pl-3';
            },
          }}
        />
      </div>
      <main className="flex min-h-0 w-full flex-auto flex-col items-center justify-center lg:w-256 lg:flex-row lg:justify-end">
        <div className="box-border flex min-h-0 w-full flex-auto flex-col text-center lg:w-1/2 lg:flex-initial lg:pl-12 lg:pr-0 xl:mx-0">
          {children}
        </div>
      </main>
      {showFooter && (
        <div className="flex justify-center text-p1 text-white underline lg:w-256 lg:justify-end lg:text-grayv2-900">
          <div className="flex flex-col justify-between text-center lg:w-1/2 lg:flex-row lg:pl-12">
            <Link href={WEB_PATHS.HELP_CENTER}>
              <a
                className="px-4 py-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('extendedFooter.helpCenter')}
              </a>
            </Link>
            <a className="px-4 py-2" href={`mailto:${contactEmail}`}>
              {contactEmail}
            </a>
          </div>
        </div>
      )}
      <div className="fixed left-0 top-0 -z-1 h-full w-full">
        <div className="hidden lg:flex">
          <Image
            layout="fill"
            objectFit="cover"
            src="/assets/login-desktop-background.svg"
            alt="Login image"
          />
        </div>
        <div className="flex lg:hidden">
          <Image
            layout="fill"
            objectFit="cover"
            src="/assets/login-mobile-background.svg"
            alt="Login image"
          />
        </div>
      </div>
    </div>
  );
}

import { decorate, IOFlowParams } from '@utils/componentDecorator';
import classNames from 'classnames';
import { FieldProps, Field } from 'formik';
import { flow, isUndefined } from 'lodash';
import { FC, ReactElement, ReactNode } from 'react';
import useTranslation from 'src/i18n/useTranslation';

export interface IInputText {
  valid?: boolean;
  inputProps?: Partial<JSX.IntrinsicElements['input']>;
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  className?: string;
}

export const InputText: FC<IInputText> = (props) => {
  const {
    inputProps = {},
    valid = true,
    leftComponent,
    rightComponent,
    className,
  } = props;
  const { className: cx, ...rest } = inputProps;

  return (
    <fieldset
      disabled={inputProps.disabled}
      className={classNames(
        'flex items-center gap-x-4 rounded-md px-3 py-2 disabled:border-grayv2-200',
        className,
        valid
          ? 'ring-1 ring-inset ring-grayv2-200 focus-within:ring-2 focus-within:ring-grayv2-700'
          : 'ring-1 ring-inset ring-redv2-200  focus-within:ring-2 focus-within:ring-redv2-400 hover:ring-redv2-300 active:ring-redv2-200',
      )}
    >
      {leftComponent && (
        <div className={classNames({ 'text-grayv2-400': inputProps.disabled })}>
          {leftComponent}
        </div>
      )}
      <input
        className={classNames(
          'placeholder-text-p1 w-full placeholder-grayv2-400 focus:outline-none focus-visible:outline-none disabled:bg-transparent disabled:text-grayv2-400',
          cx,
        )}
        {...rest}
      />
      {rightComponent && (
        <div className={classNames({ 'text-grayv2-400': inputProps.disabled })}>
          {rightComponent}
        </div>
      )}
    </fieldset>
  );
};

type FlowParams = IOFlowParams<IInputText>;

/**
 * Adding extra element or functionalities to `InputText` without having to
 * create new component or mingle props all over the place.
 * It takes advantage of component rendering function and decorator pattern.
 *
 * Each successive operations can add extra element or transform outgoing props to render to `InputText`
 *
 * Usage example
 * ```
 * InputTextDecorator.decorate(
 *  props,
 *  [
 *    InputTextDecorator.withLabel('label'),
 *    InputTextDecorator.withFooter(<div>Footer</div>)
 *  ]
 * )
 * ```
 */
export class InputTextDecorator {
  static decorate(props: IInputText, operations: any[]) {
    return decorate(
      props,
      (outProps: IInputText) => <InputText {...outProps} />,
      operations,
    );
  }

  static withFormikField(name: string) {
    return (params: FlowParams): FlowParams => {
      const { out } = params;

      params.out = (outProps) => (
        <Field name={name}>
          {(fieldProps: FieldProps) => {
            const { field, form } = fieldProps;
            const meta = form.getFieldMeta(field.name);
            const valid = !(meta.touched && meta.error);

            return out({
              ...outProps,
              valid,
              inputProps: {
                ...outProps.inputProps,
                ...field,
              },
            });
          }}
        </Field>
      );

      return params;
    };
  }

  static withLabelOnTop(
    label: ReactNode,
    required?: boolean,
    wrapperClassName = 'mb-1 flex justify-between items-center',
  ) {
    return (params: FlowParams): FlowParams => {
      const { t } = useTranslation();
      const { out } = params;

      params.out = (outProps) => (
        <label>
          <div className={wrapperClassName}>
            <span className="text-p2 font-bold">{label}</span>
            {!isUndefined(required) && (
              <span className="text-p1 text-grayv2-500">
                {required ? t('required') : 'Optional'}
              </span>
            )}
          </div>
          {out(outProps)}
        </label>
      );

      return params;
    };
  }

  static withErrorMessage(
    message: ReactNode,
    wrapperClassName = 'mt-2 mx-3 text-p1 text-redv2-600',
  ) {
    return (params: FlowParams): FlowParams => {
      const { valid } = params.inProps;
      const { out } = params;

      params.out = (outProps) => (
        <>
          {out(outProps)}
          {!valid && <div className={wrapperClassName}>{message}</div>}
        </>
      );

      return params;
    };
  }

  static withFooter(footer: ReactNode, wrapperClassName = 'mt-2 mx-3') {
    return (params: FlowParams) => {
      const { out } = params;
      params.out = (outProps) => (
        <>
          {out(outProps)}
          <div className={wrapperClassName}>{footer}</div>
        </>
      );

      return params;
    };
  }

  /**
   * Group everything together into a single element so that external parent style won't affect children.
   *
   * This function is called automatically at the end.
   *
   * No need to call this.
   */
  static _postWrap() {
    return (params: FlowParams) => {
      const { out } = params;
      params.out = (outProps) => <div>{out(outProps)}</div>;

      return params;
    };
  }
}

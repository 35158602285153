import { LanguageCode } from '@models/language';
import { useRouter } from 'next/router';
import { SelectV2, stylizeSeac } from '../SelectV2';
import { setNextLocaleCookie } from '../../../i18n/lang-utils';

import { Props } from 'react-select';
import { useEffect, useState } from 'react';
import languageApi from '@http/language.api';

export function LanguageDropdown(props: Props) {
  const router = useRouter();
  const [supportedLanguages, setSupportedLanguages] = useState([]);

  useEffect(() => {
    fetchSupportedLanguages();
  }, []);

  const { locale, defaultLocale } = router;

  const fetchSupportedLanguages = async () => {
    try {
      const response = await languageApi.getAllLanguages();

      const dropdownOptions = response
        .filter((l) => l.isActive)
        .map((l) => ({
          label: l.languageFullName,
          value: l.languageCode,
        }));

      const inactiveUserPreferredLocaleMapping = response.find(
        (l) => !l.isActive && l.languageCode === locale,
      );

      if (inactiveUserPreferredLocaleMapping) {
        dropdownOptions.unshift({
          label: `${inactiveUserPreferredLocaleMapping.languageFullName} (Inactive)`,
          value: inactiveUserPreferredLocaleMapping.languageCode,
        });
      }

      setSupportedLanguages(dropdownOptions);
    } catch (error) {
      console.error('Error fetching supported languages:', error);
    }
  };

  const handleClickLangauge = (newLocale: string) => {
    if (newLocale === locale) return;

    setNextLocaleCookie(newLocale);

    const currentPath = router.asPath.replace(`/${locale}`, '');

    const newPath = `${
      newLocale === defaultLocale ? '' : '/' + newLocale
    }${currentPath}`;

    window.location.href = newPath;
  };

  return (
    <SelectV2
      {...props}
      instanceId="language-dropdown"
      {...stylizeSeac({
        classNames: {
          container() {
            return 'w-32';
          },
          menuList() {
            return 'mb-2';
          },
          ...props.classNames,
        },
      })}
      onChange={(data: { label: string; value: LanguageCode }) =>
        handleClickLangauge(data.value)
      }
      options={supportedLanguages}
      value={
        supportedLanguages.find((option) => option.value === locale) || null
      }
      isSearchable={false}
    />
  );
}
